import { Component, OnDestroy, Input, Optional, Self, ElementRef, OnChanges } from '@angular/core';
import { ControlValueAccessor, NgControl, Validators, FormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject, Subscription } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY', // Aquí defines el formato deseado
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-input-fecha',
  templateUrl: './input-fecha.component.html',
  styleUrls: ['./input-fecha.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: InputFechaComponent },
    {provide: MAT_DATE_LOCALE, useValue: 'es-MX'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
  ],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy',
  }
})
export class InputFechaComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy, OnChanges {

  get errorState(): boolean {
    return this._errorState;
  }
  set errorState(value: boolean) {
    this._errorState = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get empty() {
    this._errorState = true;
    this.stateChanges.next();
    return !this.fecha.value;
  }

  get shouldLabelFloat() { return true; } // return this.focused || !this.empty;


  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this.fecha.setValidators(Validators.required);
    this.fecha.updateValueAndValidity();
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.fecha.disable() : this.fecha.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): string | null {
    if (this.fecha.valid) {
      return this.fecha.value;
    }
    return null;
  }
  set value(tel: string | null) {
    if (tel) {
      this.fecha.setValue(moment(tel, this.format)); // moment(this.fecha.value).format(this.format)
      this.onTouched();
    } else {
      this.fecha.reset();
    }
    this.stateChanges.next();
  }

  constructor(
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {

    this.fecha = new FormControl(null);
    fm.monitor(elRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.subsFecha = this.fecha.valueChanges.subscribe(data => {
      this._handleInput();
    });
  }
  static nextId = 0;
  @Input() private format = 'YYYY/MM/DD';
  @Input() maxFecha = moment();
  @Input() minFecha = null;
  fecha: FormControl;
  stateChanges = new Subject<void>();
  focused = false;
  subsFecha = new Subscription();
  controlType = 'app-input-fecha';
  id = `app-input-fecha-${InputFechaComponent.nextId++}`;
  describedBy = '';
  // Casos especiales
  @Input() esMayor = false;
  @Input() esMenor = false;
  @Input() mostraHint = false;
  @Input() tocado = false;
  public _errorState = false;
  public _placeholder: string;
  public _required = false;
  public _disabled = false;
  onChange = (_: any) => { };
  onTouched = () => { };

  ngOnChanges(): void {
    if (this.tocado) {
      this.onTouched();
      this.evaluarError();
      this.stateChanges.next();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef);
    this.subsFecha.unsubscribe();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input')!.focus();
    }
  }

  writeValue(tel: string | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    if (this.fecha.valid) { this._errorState = false; }
    this.onChange(moment(this.fecha.value).format(this.format) !== 'Invalid date' ? moment(this.fecha.value).format(this.format) : null);
    this.stateChanges.next();
  }

  reset() {
    setTimeout(() => {
      this.fecha.reset(null);
    }, 10);
  }

  evaluarError() {
    if (this.ngControl.touched && this.fecha.invalid) {
      this._errorState = true;
    } else {
      this._errorState = false;
    }
    this.stateChanges.next();
  }

}
